import { combineReducers } from 'redux';
import {IRootState} from "../../types/IRootState";
import loadingReducer from "./loaderReducer";
import locationReducer from "./locationReducer";
import consumptionReducer from "./consumptionReducer";
import productReducer from "./productReducer";
import bonusProductsReducer from "./bonusProductsReducer";
import checkoutReducer from "./checkoutReducer";
import configReducer from "./configReducer";
import providerReducer from "./providerReducer";
import adminReducer from "./adminReducer";

const rootReducer = combineReducers<IRootState>({
    admin: adminReducer,
    config: configReducer,
    checkout: checkoutReducer,
    loader: loadingReducer,
    location: locationReducer,
    consumption: consumptionReducer,
    product: productReducer,
    bonusProducts: bonusProductsReducer,
    provider: providerReducer,
})

export default rootReducer