import {actionIds, BaseAction} from "../common";
import IApiProductsResponse from "../../types/IApiProductsResponse";

const setProductDetailsAction: (productResponse: IApiProductsResponse) =>
    BaseAction = (productResponse) => ({
    type: actionIds.SET_PRODUCT_DETAILS,
    payload: {
        ...productResponse
    },
});

export default setProductDetailsAction;
