import {actionIds, BaseAction} from "../common";
import IBonusProduct from "../../types/IBonusProduct";
import {IBonusProductSpecialOfferDetails} from "../../types/IBonusProductSpecialOfferDetails";

interface ISetBonusProductsAction {
    bonusProducts: IBonusProduct[];
    specialOfferDetails: IBonusProductSpecialOfferDetails[];
}

const setBonusProductsAction: (data: ISetBonusProductsAction) =>
    BaseAction<ISetBonusProductsAction> = (data) => ({
    type: actionIds.SET_BONUS_PRODUCTS,
    payload: data,
});

export default setBonusProductsAction;
