import ILoaderState from "../../types/ILoaderState";
import {actionIds, BaseAction} from "../common";

const defaultState: ILoaderState = {
  loading: false,
}

const loadingReducer = (state: ILoaderState = defaultState, action: BaseAction): ILoaderState => {
  switch (action.type) {
    case actionIds.LOADING_SET:
      return {
        loading: true,
      }

    case actionIds.LOADING_UNSET:
      return {
        loading: false,
      }

    case actionIds.RESET_STORE:
      return {
        ...defaultState,
      }

    default:
      return state
  }
}

export default loadingReducer;
