import {actionIds, BaseAction} from "../common";
import {IAdminBonusProduct} from "../../types/IAdminBonusProduct";

const adminListBonusProductsResponseAction: (data: {bonusProducts: IAdminBonusProduct[]}) =>
    BaseAction = (data) => ({
    type: actionIds.ADMIN_LIST_BONUSPRODUCTS_RESPONSE,
    payload: {
        ...data,
    },
});

export default adminListBonusProductsResponseAction;
