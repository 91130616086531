import {actionIds, BaseAction} from "../common";
import IProductState from "../../types/IProductState";

const defaultState: IProductState = {
    errorCode: '',
    errorMessage: '',
    combiBonus: 0,
    combiBonusAmount: 0,
    campaignIdentifier: '',
    cancellationPeriod: 0,
    cancellationPeriodUnit: '',
    code: '',
    initialDuration: 0,
    initialDurationUnit: '',
    monthlyPrice: 0,
    name: '',
    productBonus: 0,
    savedAmount: 0,
    termsAndConditionsUrl: '',
    yearlyPrice: 0,
    gas: {
        name: '',
        code: '',
        yearlyPrice: 0,
        monthlyPrice: 0,
        price: [],
        documents: [],
        comparedProductInformation: '',
    },
    electricity: {
        name: '',
        code: '',
        yearlyPrice: 0,
        monthlyPrice: 0,
        price: [],
        documents: [],
        comparedProductInformation: '',
    }
}

const productReducer = (state: IProductState = defaultState, action: BaseAction): IProductState => {
    switch (action.type) {
        case actionIds.SET_PRODUCT_DETAILS:
            return {
                ...action.payload
            }

        case actionIds.SET_PRODUCT_ERROR:
            return {
                ...state,
                errorCode: action.payload.errorCode,
                errorMessage: action.payload.errorMessage,
            }

        case actionIds.RESET_PRODUCT_ERROR:
            return {
                ...state,
                errorCode: '',
                errorMessage: '',
            }

        case actionIds.RESET_STORE:
            return {...defaultState}

        default:
            return state
    }
}

export default productReducer
