import {actionIds, BaseAction} from "../common";
import {IBonusProductsState} from "../../types/IBonusProductsState";

const defaultState: IBonusProductsState = {
    bonusProducts: [],
    selectedBonusProduct: null,
}

const bonusProductsReducer = (state: IBonusProductsState = defaultState, action: BaseAction): IBonusProductsState => {
    switch (action.type) {
        case actionIds.SET_BONUS_PRODUCTS:
            const preSelectedBonusProduct = action.payload.bonusProducts
                .find(product => product.id === state.selectedBonusProduct?.id)
                || action.payload.bonusProducts[0]

            return {
                ...state,
                bonusProducts: action.payload.bonusProducts,
                selectedBonusProduct: preSelectedBonusProduct,
            }

        case actionIds.SET_BONUS_PRODUCT:
            return {
                ...state,
                selectedBonusProduct: action.payload.bonusProduct
            }

        default:
            return state
    }
}

export default bonusProductsReducer
