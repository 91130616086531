import {actionIds, BaseAction} from "../common";
import {IAdminCodepoolStatsResponseData} from "../../types/IAdminCodepoolStatsResponseData";

const adminCodepoolStatsResponseAction: (data: IAdminCodepoolStatsResponseData) =>
    BaseAction = (data) => ({
    type: actionIds.ADMIN_CODEPOOL_STATS_RESPONSE,
    payload: {
        ...data,
    },
});

export default adminCodepoolStatsResponseAction;
