import React, {ReactNode} from 'react'
import './PrimaryButton.scss'

interface Props {
    onClick?: (e: React.MouseEvent) => void;
    children: ReactNode;
}

const PrimaryButton = (props: Props) => {
    return (
        <button className="primary-button" onClick={props.onClick}>{props.children}</button>
    )
}

export default PrimaryButton