import {actionIds, BaseAction} from "../common";
import {IAdminFindPremiumcodesResponseData} from "../../types/IAdminFindPremiumcodesResponseData";

const adminFindPremiumcodesResponseAction: (data: IAdminFindPremiumcodesResponseData) =>
    BaseAction = (data) => ({
    type: actionIds.ADMIN_FIND_PREMIUMCODES_RESPONSE,
    payload: {
        ...data,
        premiumcodes: data.premiumcodes?.map(premiumcode => ({
            ...premiumcode,
            order_number: premiumcode.order_number.String,
            reason: premiumcode.reason.String,
            updated: premiumcode.updated.String,
        }))
    },
});

export default adminFindPremiumcodesResponseAction;
