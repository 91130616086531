import React from 'react'
import spinner from '../../../assets/spinner.svg'
import './Spinner.scss'

interface Props {

}

const Spinner = (props: Props) => {
    return (
        <div className="spinner">
            <div className="spinner__background">
                <img
                    alt="Wird geladen..."
                    className="spinner__image"
                    src={spinner}
                />
            </div>
        </div>
    )
}

export default Spinner