import {actionIds, BaseAction} from "../common";

const validateIbanResponseAction: (success: boolean, errorMessage: string) =>
    BaseAction = (success, errorMessage) => ({
    type: actionIds.VALIDATE_IBAN_RESPONSE,
    payload: {
        success,
        errorMessage
    },
});

export default validateIbanResponseAction;
