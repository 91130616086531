// import React, {useState} from 'react';
// import './errorPage.scss';
// import {Link, Redirect} from 'react-router-dom';
// import error from '../../assets/error.png';
// import routes from "../../config/routes";
// import PrimaryButton from "../atoms/PrimaryButton/PrimaryButton";
// import Logo from "../atoms/Logo/Logo";

const ErrorPage = () => {
    // const [redirect, setRedirect] = useState('')
    document.location.href = 'https://www.stadtenergie.de'

    return null

    // return (
    //     <div className="fullwidth-errorPage-mobile">
    //         {redirect && <Redirect to={redirect}/>}
    //         {/* Background 1 */}
    //         <div className="repeat-x-errorPage-mobile bg-1-errorPage-mobile">
    //             <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingLeft: 20, paddingTop: 20 }}>
    //                 <Logo className="error-page__logo"/>
    //             </div>
    //
    //             {/* Circle Content */}
    //             <div style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
    //                 <div className="circle-container-errorPage-mobile">
    //                     <div className="badge-errorPage-mobile">
    //                         {/* TODO: Replace missing icon */}
    //                         <img
    //                             alt="logo"
    //                             src={error}
    //                             width="70"
    //                             height="70"
    //                             style={{ paddingTop: 70 }}
    //                         />
    //                         <p style={{ fontSize: 24, fontWeight: 'bold', fontFamily: 'Poppins', color: '#87289b' }}>
    //                             Die Seite konnte nicht
    //                             </p>
    //                         <p style={{ fontSize: 24, fontWeight: 'bold', fontFamily: 'Poppins', color: '#87289b', marginBottom: 30 }}>
    //                             gefunden werden.
    //                             </p>
    //                         <PrimaryButton onClick={() => setRedirect(routes.HOME_PAGE)}>Zurück zur Startseite</PrimaryButton>
    //                     </div>
    //                 </div>
    //             </div>
    //
    //             {/* External Page */}
    //             <p className="footer-ContactUS" style={{ paddingTop: 30 }}>
    //                 <Link to="#" style={{ textDecoration: 'none', color: '#282d32' }}>Impressum  |  </Link>
    //                 <Link to="#" style={{ textDecoration: 'none', color: '#282d32' }}>Datenschutz  |  </Link>
    //                 <Link to="#" style={{ textDecoration: 'none', color: '#282d32' }}>Schufa  |  </Link>
    //                 <Link to="#" style={{ textDecoration: 'none', color: '#282d32' }}>FAQ</Link>
    //             </p>
    //         </div>
    //     </div >
    // )
}

export default ErrorPage