import {actionIds, BaseAction} from "../common";

const setLocationErrorAction: (errorCode: string, errorMessage: string) =>
    BaseAction = (errorCode, errorMessage) => ({
    type: actionIds.SET_LOCATION_ERROR,
    payload: {
        errorCode,
        errorMessage,
    },
});

export default setLocationErrorAction;
