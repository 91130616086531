import INormalizedData from "../types/INormalizedData";
import {API_PROVIDERS_PATH} from "../config/api";
import IProvider from "../types/IProvider";
import {TProductType} from "../types/TProductType";

const fetchProviderData = (apiUrl: string,
                           type: TProductType = 'electricity'): Promise<INormalizedData<IProvider[]>> => {
    return fetch(`${apiUrl}${API_PROVIDERS_PATH}?type=${type}`, { headers: { 'cache-control': 'no-store' }})
        .then(res => res.json());
};

export default fetchProviderData;
