import React, {lazy, Suspense} from 'react';
import {Route} from 'react-router';
import ErrorPage from "./components/screens/errorPage";
// import HomePage from "./components/pages/HomePage";
// import ResultsPage from "./components/pages/ResultsPage";
// import CheckoutStep1Page from "./components/pages/CheckoutStep1Page";
// import CheckoutStep2Page from "./components/pages/CheckoutStep2Page";
// import CheckoutStep3Page from "./components/pages/CheckoutStep3Page";
// import ThankYouPage from "./components/pages/ThankYouPage";
// import FaqPage from "./components/pages/FaqPage";
// import TariffPage from "./components/pages/TariffPage";
import routes from "./config/routes";
import {Router, Switch} from 'react-router-dom';
import Spinner from "./components/atoms/Spinner/Spinner";
import ILoaderState from "./types/ILoaderState";
import {IRootState} from "./types/IRootState";
import {connect} from "react-redux";
import AdminProtectedRoute from "./admin/atoms/AdminProtectedRoute/AdminProtectedRoute";

const AdminIndexPage = lazy(() => import('./admin/pages/AdminIndexPage'));
const AdminDashboardPage = lazy(() => import('./admin/pages/AdminDashboardPage'));
const AdminLoginPage = lazy(() => import('./admin/pages/AdminLoginPage'));
const AdminPremiumcodesPage = lazy(() => import('./admin/pages/AdminPremiumcodesPage'));
const AdminFindPremiumcodesPage = lazy(() => import('./admin/pages/AdminFindPremiumcodesPage'));
const AdminInvalidatePremiumcodesPage = lazy(() => import('./admin/pages/AdminInvalidatePremiumcodesPage'));
const AdminImportPremiumcodesPage = lazy(() => import('./admin/pages/AdminImportPremiumcodesPage'));
const AdminBonusProductsPage = lazy(() => import('./admin/pages/AdminBonusProductsPage'));
const AdminBonusProductsListPage = lazy(() => import('./admin/pages/AdminBonusProductsListPage'));
const AdminBonusProductsEditPage = lazy(() => import('./admin/pages/AdminBonusProductsEditPage'));

interface Props {
    history: any;
}

interface IStateProps {
    loader: ILoaderState;
}

const RouterWrapper = (props: Props & IStateProps) => {
    const {loader: {loading}, history} = props;

    return (
        <Router history={history}>
            <Suspense fallback={<Spinner/>}>
                {loading && (<Spinner/>)}
                <Switch>
                    {/*<Route path={routes.HOME_PAGE} exact component={HomePage}/>*/}
                    {/*<Route path={routes.TARIFF_PAGE} exact component={TariffPage}/>*/}
                    {/*<Route path={routes.RESULTS_PAGE} exact component={ResultsPage}/>*/}
                    {/*<Route path={routes.CHECKOUT_PAGE_STEP1} exact component={CheckoutStep1Page}/>*/}
                    {/*<Route path={routes.CHECKOUT_PAGE_STEP2} exact component={CheckoutStep2Page}/>*/}
                    {/*<Route path={routes.CHECKOUT_PAGE_STEP3} exact component={CheckoutStep3Page}/>*/}
                    {/*<Route path={routes.THANK_YOU_PAGE} exact component={ThankYouPage}/>*/}
                    {/*<Route path={routes.FAQ_PAGE} exact component={FaqPage}/>*/}
                    <Route path={routes.ADMIN_LOGIN} exact component={AdminLoginPage}/>
                    <AdminProtectedRoute path={routes.ADMIN_INDEX} exact><AdminIndexPage/></AdminProtectedRoute>
                    <AdminProtectedRoute path={routes.ADMIN_DASHBOARD} exact><AdminDashboardPage/></AdminProtectedRoute>
                    <AdminProtectedRoute path={routes.ADMIN_PREMIUMCODES} exact><AdminPremiumcodesPage/></AdminProtectedRoute>
                    <AdminProtectedRoute path={routes.ADMIN_FIND_PREMIUMCODES} exact><AdminFindPremiumcodesPage/></AdminProtectedRoute>
                    <AdminProtectedRoute path={routes.ADMIN_INVALIDATE_PREMIUMCODES} exact><AdminInvalidatePremiumcodesPage/></AdminProtectedRoute>
                    <AdminProtectedRoute path={routes.ADMIN_IMPORT_PREMIUMCODES} exact><AdminImportPremiumcodesPage/></AdminProtectedRoute>
                    <AdminProtectedRoute path={routes.ADMIN_BONUSPRODUCTS} exact><AdminBonusProductsPage/></AdminProtectedRoute>
                    <AdminProtectedRoute path={routes.ADMIN_BONUSPRODUCTS_LIST} exact><AdminBonusProductsListPage/></AdminProtectedRoute>
                    <AdminProtectedRoute path={routes.ADMIN_BONUSPRODUCTS_EDIT + ':productId'} exact><AdminBonusProductsEditPage/></AdminProtectedRoute>
                    <Route component={ErrorPage}/>
                </Switch>
            </Suspense>
        </Router>
    )
};

const mapStateToProps = (state: IRootState): IStateProps => {
    return {
        loader: state.loader,
    };
};

export default connect(mapStateToProps)(RouterWrapper);