import {actionIds, BaseAction} from "../common";
import ILocationState from "../../types/ILocationState";

const defaultState: ILocationState = {
    postcode: '',
    postcodeLoading: false,
    available: false,
    cities: [],
    selectedCity: null,
    selectedStreet: null,
    selectedHouseNumber: '',
    errorCode: '',
    errorMessage: '',
    streets: [],
}

const locationReducer = (state: ILocationState = defaultState, action: BaseAction): ILocationState => {
    switch (action.type) {
        case actionIds.SET_LOCATION_DETAILS:
            return {
                ...state,
                postcodeLoading: false,
                available: action.payload.available,
                cities: action.payload.cities
            }

        case actionIds.SET_POSTCODE:
            return {
                ...state,
                postcode: action.payload.postcode,
                postcodeLoading: action.payload.postcode.length >= 5,
                available: false,
                cities: [],
                errorCode: '',
                errorMessage: '',
                selectedCity: null,
                selectedStreet: null,
                selectedHouseNumber: '',
                streets: []
            }

        case actionIds.SET_LOCATION_CITY:
            return {
                ...state,
                selectedCity: action.payload.city,
                errorCode: '',
                errorMessage: '',
                streets: []
            }

        case actionIds.SET_LOCATION_STREET:
            return {
                ...state,
                selectedStreet: action.payload.street
            }

        case actionIds.SET_LOCATION_STREETS:
            return {
                ...state,
                streets: action.payload.streets
            }

        case actionIds.SET_LOCATION_HOUSE_NUMBER:
            return {
                ...state,
                selectedHouseNumber: action.payload.houseNumber
            }

        case actionIds.SET_LOCATION_ERROR:
            return {
                ...state,
                postcodeLoading: false,
                errorCode: action.payload.errorCode,
                errorMessage: action.payload.errorMessage,
            }

        case actionIds.CLEAR_LOCATION_ERROR:
            return {
                ...state,
                errorCode: ''
            }

        case actionIds.RESET_STORE:
            return defaultState

        default:
            return state
    }
}

export default locationReducer
