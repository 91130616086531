const routes = {
    HOME_PAGE: '/',
    TARIFF_PAGE: '/tarif/',
    RESULTS_PAGE: '/praemie/',
    CHECKOUT_PAGE_STEP1: '/bestellen/persoenliche-daten/',
    CHECKOUT_PAGE_STEP2: '/bestellen/belieferung/',
    CHECKOUT_PAGE_STEP3: '/bestellen/zusammenfassung/',
    THANK_YOU_PAGE: '/bestellen/prozess/erfolg/',
    ERROR_PAGE: '/fehler/',
    FAQ_PAGE: '/faq/',

    ADMIN_INDEX: '/admin/',
    ADMIN_DASHBOARD: '/admin/dashboard/',
    ADMIN_LOGIN: '/admin/login/',
    ADMIN_PREMIUMCODES: '/admin/premiumcodes/',
    ADMIN_FIND_PREMIUMCODES: '/admin/premiumcodes/find/',
    ADMIN_INVALIDATE_PREMIUMCODES: '/admin/premiumcodes/invalidate/',
    ADMIN_IMPORT_PREMIUMCODES: '/admin/premiumcodes/import/',
    ADMIN_BONUSPRODUCTS: '/admin/bonusproducts/',
    ADMIN_BONUSPRODUCTS_LIST: '/admin/bonusproducts/list/',
    ADMIN_BONUSPRODUCTS_EDIT: '/admin/bonusproducts/edit/',
}

export default routes