import {actionIds, BaseAction} from "../common";

const getLocationDetailsAction: (postcode: string) =>
    BaseAction = (postcode) => ({
    type: actionIds.GET_LOCATION_DETAILS,
    payload: {
        postcode
    },
});

export default getLocationDetailsAction;
