import internalServerErrorAction from "../../state/actions/internalServerError";
import loadingUnsetAction from "../../state/actions/loadingUnset";
import {actionIds} from "../../state/common";
import setBonusProductsAction from "../../state/actions/setBonusProducts";
import submitCheckoutFormSuccessAction from "../../state/actions/submitCheckoutFormSuccess";
import setConfigAction from "../../state/actions/setConfig";
import adminLoginResponseAction from "../../state/actions/adminLoginResponse";
import adminCodepoolStatsResponseAction from "../../state/actions/adminCodepoolStatsResponse";
import adminFindPremiumcodesResponseAction from "../../state/actions/adminFindPremiumcodesResponse";
import errorAccessDeniedAction from "../../state/actions/errorAccessDenied";
import adminInvalidatePremiumcodesResponseAction from "../../state/actions/adminInvalidatePremiumcodesResponse";
import adminListBonusProductsResponseAction from "../../state/actions/adminListBonusProductsResponse";
import adminGetBonusProductResponseAction from "../../state/actions/adminGetBonusProductResponse";
import adminUpdateBonusProductResponseAction from "../../state/actions/adminUpdateBonusProductResponse";

const handleMessages = (dispatch) => (event) => {
  dispatch(loadingUnsetAction());
  const data = JSON.parse(event.data);

  switch (data.type) {
    case actionIds.SET_CONFIG:
      dispatch(setConfigAction(data.payload));
      break;
    case actionIds.ERROR_INTERNAL_SERVER_ERROR:
      dispatch(internalServerErrorAction());
      break;
    case actionIds.ERROR_ACCESS_DENIED:
      dispatch(errorAccessDeniedAction());
      break;
    case actionIds.BONUS_PRODUCTS:
      dispatch(setBonusProductsAction(data.payload));
      break;
    case actionIds.SUBMIT_CHECKOUT_FORM_SUCCESS:
      dispatch(submitCheckoutFormSuccessAction(data.payload));
      break;

    case actionIds.ADMIN_LOGIN_RESPONSE:
      dispatch(adminLoginResponseAction(data.payload));
      break;
    case actionIds.ADMIN_CODEPOOL_STATS_RESPONSE:
      dispatch(adminCodepoolStatsResponseAction(data.payload));
      break;
    case actionIds.ADMIN_LIST_BONUSPRODUCTS_RESPONSE:
      dispatch(adminListBonusProductsResponseAction(data.payload));
      break;
    case actionIds.ADMIN_GET_BONUSPRODUCT_RESPONSE:
      dispatch(adminGetBonusProductResponseAction(data.payload));
      break;
    case actionIds.ADMIN_UPDATE_BONUSPRODUCT_RESPONSE:
      dispatch(adminUpdateBonusProductResponseAction(data.payload));
      break;
    case actionIds.ADMIN_FIND_PREMIUMCODES_RESPONSE:
      dispatch(adminFindPremiumcodesResponseAction(data.payload));
      break;
    case actionIds.ADMIN_INVALIDATE_PREMIUMCODES_RESPONSE:
      dispatch(adminInvalidatePremiumcodesResponseAction(data.payload));
      break;

    default:
      console.error('websocket message unknown', data);
  }
}

export default handleMessages