import handleMessages from './handleMessages';
import { WebSocketConfig } from '../../config/websocket';


interface IWebSocketSafeSend {
  safeSend?: (request: any) => void
}

export interface IWebSocket extends WebSocket, IWebSocketSafeSend {}

const setupSocket = (dispatch) => {
  let socket: IWebSocket;

  const connect = () => {
    const websocketUrl =
      document.location.protocol.indexOf('https') !== -1 ? WebSocketConfig.urlTls : WebSocketConfig.url
    socket = new WebSocket(websocketUrl.replace('{domain}', document.location.hostname))

    let sendQueue: any[] = []
    let isConnected = false

    socket.onopen = () => {
      if(sendQueue.length > 0) {
        sendQueue = sendQueue.filter(request => {
          socket.send(request);
          return false
        })
      }
      isConnected = true;
    }

    socket.onmessage = handleMessages(dispatch)

    socket.onclose = (e) => {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
      setTimeout(function() {
        connect();
      }, 1000);
    }

    socket.onerror = () => {
      // TODO: Add error handling
      socket.close();
    }

    socket.safeSend = (request) => {
      if (isConnected) {
        socket.send(request)
      } else {
        sendQueue.push(request)
      }
    }

    return socket
  }

  return connect()
}

export default setupSocket
