import INormalizedData from "../types/INormalizedData";
import {API_PRODUCTS_PATH, CAMPAIGN_IDENTIFIER, CAMPAIGN_IDENTIFIER_COMBI} from "../config/api";
import {TProductType} from "../types/TProductType";
import IApiProductsResponse from "../types/IApiProductsResponse";

const fetchProductsData = (apiUrl: string,
                           cityId: number,
                           consumptionGas: number,
                           consumptionElectricity: number,
                           street?: string,
                           houseNumber?: string,
                           type: TProductType = 'electricity'): Promise<INormalizedData<IApiProductsResponse>> => {

    const campaignIdentifier = type === 'combi' ? CAMPAIGN_IDENTIFIER_COMBI : CAMPAIGN_IDENTIFIER;
    let requestUrl
    if (type === 'electricity') {
        requestUrl = `${apiUrl}${API_PRODUCTS_PATH}?campaignIdentifier=${campaignIdentifier}&cityId=${cityId}&usage=${consumptionElectricity}&type=${type}`;
    } else if (type === 'gas') {
        requestUrl = `${apiUrl}${API_PRODUCTS_PATH}?campaignIdentifier=${campaignIdentifier}&cityId=${cityId}&usage=${consumptionGas}&type=${type}`;
    } else {
        requestUrl = `${apiUrl}${API_PRODUCTS_PATH}?campaignIdentifier=${campaignIdentifier}&cityId=${cityId}&gasUsage=${consumptionGas}&electricityUsage=${consumptionElectricity}&type=${type}`;
    }

    if (street && houseNumber) {
        requestUrl += `&street=${street}&houseNumber=${houseNumber}`;
    }

    const defaultProductTypeData = {
        name: '',
        code: '',
        yearlyPrice: 0,
        monthlyPrice: 0,
        price: [],
        documents: [],
        comparedProductInformation: '',
    }

    let options: RequestInit = {headers: {'cache-control': 'no-store'}};

    return fetch(requestUrl, options)
        .then(res => res.json())
        .then(data => {
            if (typeof data['error-code'] !== 'undefined') {
                data.error = true
                data.errorCode = data['error-code']
                data.errorMessage = data['error-message']
            } else {
                data.error = false
            }
            data['campaignIdentifier'] = campaignIdentifier;
            if (type === 'electricity' || type === 'gas') {
                data['electricity'] = Object.assign({}, defaultProductTypeData);
                data['gas'] = Object.assign({}, defaultProductTypeData);
                data[type] = {
                    name: data.name,
                    code: data.code,
                    yearlyPrice: data.yearlyPrice,
                    monthlyPrice: data.monthlyPrice,
                    price: data.price,
                    documents: data.documents,
                    comparedProductInformation: data.comparedProductInformation
                }
            }

            return data;
        });
};

export default fetchProductsData;



