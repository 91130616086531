import {actionIds, BaseAction} from "../common";
import ICity from "../../types/ICity";

const setLocationCityAction: (city: ICity | null) =>
    BaseAction = (city) => ({
    type: actionIds.SET_LOCATION_CITY,
    payload: {
        city,
    },
});

export default setLocationCityAction;
