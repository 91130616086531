import {actionIds, BaseAction} from "../common";
import IProviderState from "../../types/IProviderState";

const defaultState: IProviderState = {
    providersElectricity: [],
    providersGas: [],
    loadingProviders: false,
    loadingProvidersStarted: new Date(0),
    previousProviderGas: null,
    previousProviderElectricity: null
}

const providerReducer = (state: IProviderState = defaultState, action: BaseAction): IProviderState => {
    switch (action.type) {
        case actionIds.GET_PROVIDERS:
            return {
                ...state,
                loadingProviders: true,
                loadingProvidersStarted: new Date(),
            }

        case actionIds.SET_PROVIDERS:
            let providerInfo
            if (action.payload.type === 'electricity') {
                providerInfo = {providersElectricity: action.payload.providers}
            } else if (action.payload.type === 'gas') {
                providerInfo = {providersGas: action.payload.providers}
            }

            return {
                ...state,
                ...providerInfo,
                loadingProviders: false,
                loadingProvidersStarted: new Date(0),
            }

        case actionIds.SET_PREVIOUS_PROVIDER:
            if (action.payload.type === 'electricity') {
                return {
                    ...state,
                    previousProviderElectricity: action.payload.provider,
                }
            } else if (action.payload.type === 'gas') {
                return {
                    ...state,
                    previousProviderGas: action.payload.provider,
                }
            }
            return state


        case actionIds.RESET_STORE:
            return {
                ...defaultState,
            }

        default:
            return state
    }
}

export default providerReducer;
