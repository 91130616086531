import {actionIds, BaseAction} from "../common";

const setLocationStreetsAction: (streets: string[]) =>
    BaseAction = (streets) => ({
    type: actionIds.SET_LOCATION_STREETS,
    payload: {
        streets
    },
});

export default setLocationStreetsAction;
