import {all, call, ForkEffect, put, spawn, takeEvery, takeLatest} from 'redux-saga/effects';
import {actionIds, BaseAction} from "../common";
import getLocationDetailsAction from "../actions/getLocationDetails";
import fetchLocationData from "../../api/location";
import IApiLocationResponse from "../../types/IApiLocationResponse";
import setLocationDetailsAction from "../actions/setLocationDetails";
import setLocationErrorAction from "../actions/setLocationError";
import setLocationCityAction from "../actions/setLocationCity";
import loadingUnsetAction from "../actions/loadingUnset";
import routes from "../../config/routes";
import navigate from "../actions/navigate";
import {IProductDetailsActionParams} from "../../types/actions/IProductDetailsActionParams";
import fetchProductsData from "../../api/products";
import setProductDetailsAction from "../actions/setProductDetails";
import {IWebSocket} from "../../api/sockets";
import {IConfig} from "../../types/IConfig";
import {ILocationStreetsActionParams} from "../../types/actions/ILocationStreetsActionParams";
import fetchStreetsData from "../../api/streets";
import setLocationStreetsAction from "../actions/setLocationStreets";
import {gtmEvent} from "../../lib/gtm";
import fetchProviderData from "../../api/providers";
import setProvidersAction from "../actions/setProviders";
import loadingSetAction from "../actions/loadingSet";
import fetchIbanData from "../../api/iban";
import {IValidateIbanRequestActionParams} from "../../types/actions/IValidateIbanRequestActionParams";
import validateIbanResponseAction from "../actions/validateIbanResponse";
import IApiIbanResponse from "../../types/IApiIbanResponse";
import setProductErrorAction from "../actions/setProductError";

type TSaga = (params?: any) => Generator<ForkEffect<never>, void, unknown>

interface IRootSagaParams {
    history: any,
    socket: IWebSocket,
}

let apiUrl: string = ''
const rootSaga = function* root(params: IRootSagaParams) {
    const sagas: TSaga[] = [
        watchGetConfigRequest,
        watchSetConfigRequest,
        watchGetBonusProductsRequest,
        watchOnNavigateAction,
        watchSetPostcodeRequest,
        watchFormSubmitRequest,
        watchGetLocationDetailsRequest,
        watchGetLocationStreetsRequest,
        watchValidateIbanRequest,
        watchGetProductDetailsRequest,
        watchGetProvidersRequest,

        watchAdminLoginRequest,
        watchAdminLogpoutRequest,
        watchAdminCodepoolStatsRequest,
        watchAdminListBonusProductsRequest,
        watchAdminGetBonusProductRequest,
        watchAdminUpdateBonusProductRequest,
        watchAdminFindPremiumcodesRequest,
        watchAdminInvalidatePremiumcodesRequest,
    ];

    yield all(sagas.map(saga =>
        spawn(function* () {
            while (true) {
                try {
                    yield call(saga, params);
                    break
                } catch (e) {
                    console.error(e)
                }
            }
        }))
    )
}

function* watchGetBonusProductsRequest(params) {
    yield takeLatest(actionIds.GET_BONUS_PRODUCTS, function* (action) {
        yield put(loadingUnsetAction())
        params.socket.safeSend(JSON.stringify(action))
    })
}

function* watchOnNavigateAction(params) {
    yield takeEvery(actionIds.NAVIGATE, function* (action: BaseAction) {
        yield put(loadingUnsetAction())
        params.history.push(action.payload.url)
    })
}

function* watchSetPostcodeRequest() {
    yield takeLatest(actionIds.SET_POSTCODE, function* (action: BaseAction) {
        yield put(getLocationDetailsAction(action.payload.postcode))
        yield put(loadingUnsetAction())
    })
}

function* watchFormSubmitRequest(params) {
    yield takeLatest(actionIds.SUBMIT_CHECKOUT_FORM, function* (action: BaseAction) {
        yield params.socket.safeSend(JSON.stringify(action))
    })
}

function* watchGetConfigRequest(params) {
    yield takeLatest(actionIds.GET_CONFIG, function* (action: BaseAction) {
        yield params.socket.safeSend(JSON.stringify(action))
    })
}

function* watchSetConfigRequest(params) {
    yield takeLatest(actionIds.SET_CONFIG, function* (action: BaseAction<IConfig>) {
        apiUrl = action.payload.apiUrl
        yield apiUrl
    })
}

function* watchGetLocationDetailsRequest() {
    yield takeLatest(actionIds.GET_LOCATION_DETAILS, function* (action: BaseAction) {
        if (action.payload.postcode.length === 5) {
            const locationData: IApiLocationResponse = yield fetchLocationData(apiUrl, action.payload.postcode);
            if (typeof locationData.available_electricity_product !== 'undefined' && typeof locationData.cities !== 'undefined') {
                yield put(setLocationDetailsAction(
                    action.payload.postcode,
                    locationData.available_electricity_product,
                    locationData.cities
                ))
                yield put(loadingUnsetAction())

                // If there is only one city, we can pre-select it and dispatch the setLocationCityAction
                if (locationData.cities.length === 1) {
                    const city = locationData.cities[0]
                    yield put(setLocationCityAction(city))
                }
            } else if (typeof locationData['error-code'] !== 'undefined') {
                yield put(setLocationErrorAction(locationData['error-code'], locationData['error-message'] ?? ''))
            }
        }
    })
}

function* watchGetLocationStreetsRequest() {
    yield takeLatest(actionIds.GET_LOCATION_STREETS, function* (action: BaseAction<ILocationStreetsActionParams>) {
        const streets = yield fetchStreetsData(
            apiUrl,
            action.payload.postcode,
        )
        yield put(setLocationStreetsAction(streets))
    })
}

function* watchValidateIbanRequest() {
    yield takeLatest(actionIds.VALIDATE_IBAN_REQUEST, function* (action: BaseAction<IValidateIbanRequestActionParams>) {
        let result: IApiIbanResponse = {
            success: false,
            messageLocalized: ''
        }
        if (action.payload.iban.trim() !== '') {
            result = yield fetchIbanData(
                apiUrl,
                action.payload.iban,
            )
        }
        yield put(validateIbanResponseAction(result.success, result.messageLocalized))
    })
}

function* watchGetProvidersRequest() {
    yield takeLatest(actionIds.GET_PROVIDERS, function* (action: BaseAction<any>) {
        yield put(loadingSetAction())
        const providers = yield fetchProviderData(
            apiUrl,
            action.payload.type
        )
        yield put(setProvidersAction(providers, action.payload.type))
        yield put(loadingUnsetAction())
    })
}

function* watchGetProductDetailsRequest() {
    yield takeLatest(actionIds.GET_PRODUCT_DETAILS, function* (action: BaseAction<IProductDetailsActionParams>) {
        const productDetails = yield fetchProductsData(
            apiUrl,
            action.payload.cityId,
            action.payload.consumptionGas,
            action.payload.consumptionElectricity,
            action.payload.street ?? '',
            action.payload.houseNumber,
            action.payload.type
        )
        if(productDetails.error) {
            yield put(setProductErrorAction(productDetails.errorCode, productDetails.errorMessage))
        } else {
            yield put(setProductDetailsAction(productDetails))
            yield gtmEvent('in den Einkaufswagen')
            yield put(navigate({
                url: routes.RESULTS_PAGE,
            }))
        }
    })
}

// ADMIN

function* watchAdminLoginRequest(params) {
    yield takeLatest(actionIds.ADMIN_LOGIN, function* (action) {
        yield put(loadingUnsetAction())
        params.socket.safeSend(JSON.stringify(action))
    })
}

function* watchAdminLogpoutRequest(params) {
    yield takeLatest(actionIds.ADMIN_LOGOUT, function* (action) {
        yield put(loadingUnsetAction())
        params.socket.safeSend(JSON.stringify(action))
    })
}

function* watchAdminCodepoolStatsRequest(params) {
    yield takeLatest(actionIds.ADMIN_CODEPOOL_STATS, function* (action) {
        yield put(loadingUnsetAction())
        params.socket.safeSend(JSON.stringify(action))
    })
}

function* watchAdminListBonusProductsRequest(params) {
    yield takeLatest(actionIds.ADMIN_LIST_BONUSPRODUCTS, function* (action) {
        yield put(loadingUnsetAction())
        params.socket.safeSend(JSON.stringify(action))
    })
}

function* watchAdminGetBonusProductRequest(params) {
    yield takeLatest(actionIds.ADMIN_GET_BONUSPRODUCT, function* (action) {
        yield put(loadingUnsetAction())
        params.socket.safeSend(JSON.stringify(action))
    })
}

function* watchAdminUpdateBonusProductRequest(params) {
    yield takeLatest(actionIds.ADMIN_UPDATE_BONUSPRODUCT, function* (action) {
        yield put(loadingUnsetAction())
        params.socket.safeSend(JSON.stringify(action))
    })
}

function* watchAdminFindPremiumcodesRequest(params) {
    yield takeLatest(actionIds.ADMIN_FIND_PREMIUMCODES, function* (action) {
        yield put(loadingUnsetAction())
        params.socket.safeSend(JSON.stringify(action))
    })
}

function* watchAdminInvalidatePremiumcodesRequest(params) {
    yield takeLatest(actionIds.ADMIN_INVALIDATE_PREMIUMCODES, function* (action) {
        yield put(loadingUnsetAction())
        params.socket.safeSend(JSON.stringify(action))
    })
}

export default rootSaga;