import {ICheckoutState} from "../../types/ICheckoutState";
import {actionIds, BaseAction} from "../common";
import {gtmEvent} from "../../lib/gtm";

const defaultState: ICheckoutState = {
    customerData: {
        customerSalutation: '',
        customerFirstname: '',
        customerSurname: '',
        customerPhonenumber: '',
        customerPhonenumberAreaCode: '',
        customerEmail: '',
        customerDateOfBirth: '',
        addressAddressAddition: '',
        addressZip: '',
        addressCity: '',
        addressStreet: '',
        addressHouseNumber: '',
        consumption: 2275,
    },
    contractData: {
        recentlyMoved: false,
        recentlyMovedDate: '',
        requestedDeliveryDate: '',
        hasRequestedDeliveryDate: false,
        requestedCancellation: false,
        previousProviderElectricity: '',
        previousProviderGas: '',
        previousCustomerNumberElectricity: '',
        previousCustomerNumberGas: '',
        meterIdentifierElectricity: '',
        meterIdentifierGas: '',
        // meterValue: '',
        iban: '',
        allowDirectDebit: false,
    },
    errors: {
        iban: null,
    },
    ibanValidated: false,
    success: false,
    orderNumber: '',
    premiumCode: null,
}

const checkoutReducer = (state: ICheckoutState = defaultState, action: BaseAction): ICheckoutState => {
    switch (action.type) {
        case actionIds.SET_CONSUMPTION:
            return {
                ...state,
                customerData: {
                    ...state.customerData,
                    consumption: action.payload.consumption,
                }
            }

        case actionIds.VALIDATE_IBAN_RESPONSE:
            return {
                ...state,
                ibanValidated: action.payload.success,
                errors: {
                    ...state.errors,
                    iban: !action.payload.success ? action.payload.errorMessage : null,
                }
            }

        case actionIds.VALIDATE_IBAN_RESET:
            return {
                ...state,
                ibanValidated: false,
                errors: {
                    ...state.errors,
                    iban: null,
                }
            }

        case actionIds.CHANGE_CUSTOMER_DATA_FORM:
            return {
                ...state,
                customerData: {
                    ...state.customerData,
                    ...action.payload.data,
                },
            }

        case actionIds.SET_POSTCODE:
            return {
                ...state,
                customerData: {
                    ...state.customerData,
                    addressStreet: '',
                    addressHouseNumber: '',
                },
            }

        case actionIds.SET_LOCATION_STREET:
            return {
                ...state,
                customerData: {
                    ...state.customerData,
                    addressStreet: action.payload.street ?? '',
                },
            }

        case actionIds.SET_LOCATION_HOUSE_NUMBER:
            return {
                ...state,
                customerData: {
                    ...state.customerData,
                    addressHouseNumber: action.payload.houseNumber,
                },
            }

        case actionIds.CHANGE_CONTRACT_DATA_FORM:
            return {
                ...state,
                contractData: {
                    ...state.contractData,
                    ...action.payload.data,
                },
            }

        case actionIds.SUBMIT_CHECKOUT_FORM_SUCCESS:
            gtmEvent('Kauf')
            return {
                ...state,
                success: action.payload.success,
                orderNumber: action.payload.orderNumber,
                premiumCode: action.payload.premiumCode,
            }

        case actionIds.RESET_STORE:
            return {
                ...defaultState,
                success: false,
                orderNumber: state.orderNumber,
                premiumCode: state.premiumCode,
            }

        default:
            return state;
    }
}

export default checkoutReducer
