import { createGlobalStyle } from 'styled-components';

// Global style only required for CookieBanner

const GlobalStyle = createGlobalStyle`
  .usercentrics-button * {
    font-family: Poppins, sans-serif !important;
    line-height: 1.5;
  }
  
  #usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-header {
    background-color: rgba(40, 45, 50, 0.9) !important;
    border-top-left-radius: 24px !important;
    border-top-right-radius: 24px !important;
  }

  #usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-body .uc-content {
    overflow-x: hidden;
  }

  #usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-info-modal-sidebar .uc-info-modal-sidebar-nav,
  #usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-body .uc-content .uc-subconsent-section {
    background-color: #78c8be !important;
  }

  #usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-content,
  .usercentrics-button.layout-1 #uc-corner-modal.layout-1 .uc-corner-modal .uc-corner-modal-content {
    border-radius: 24px !important;
  }

  #usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-footer .uc-save-settings-and-close-button,
  .usercentrics-button.layout-1 .uc-corner-modal .uc-corner-modal-content .uc-card-footer .uc-btn[data-event="save-settings"] {
    background-color: #ff5f50 !important;
    color: #fff !important;
    font-weight: bold !important;
    border-top-left-radius: 200px !important;
    border-top-right-radius: 200px !important;
    border-bottom-left-radius: 200px !important;
    border-bottom-right-radius: 200px !important;
    padding: inherit !important;
  }

  #usercentrics-button #uc-consents-info-modal .uc-consents-info-modal-wrapper .uc-modal-header,
  .usercentrics-button.layout-1 #uc-corner-modal.layout-1 .uc-corner-modal .uc-corner-modal-content .uc-card-header {
    background-color: rgba(40, 45, 50, 0.9) !important;
    border-top-left-radius: 24px !important;
    border-top-right-radius: 24px !important;
    * { 
      color: #fff !important;
    }
  }

  .usercentrics-button.layout-1 #uc-corner-modal.layout-1 .uc-corner-modal .uc-corner-modal-content .uc-card-footer {
    background-color: #fff;
    border-bottom-left-radius: 24px !important;
    border-bottom-right-radius: 24px !important;
    min-height: initial !important;
  }

  .usercentrics-button .uc-corner-modal .uc-corner-modal-content .uc-nav-tabs li {
    background-color: #78c8be;
    > a, a.uc-active {
      color: rgba(40, 45, 50, 0.9) !important;
    }
    > a.uc-active {
      border-bottom: 2px solid rgba(40, 45, 50, 0.9) !important;
    }
  }

  .usercentrics-button .uc-floating-button-wrapper {
    opacity: 0.5;
    transition: opacity 0.5s;
    :hover {
      opacity: 1;
    }
    svg {
      circle {
        fill: #ff5f50;
      }
      #speechbubble {
        fill: rgba(40, 45, 50, 0.9);
      }
      #fingerprint {
        fill:#ff5f50;
      }
    }
  }
  #usercentrics-button #uc-center-modal .center-modal-wrapper.inactive-background-wrapper .secondary-basic .uc-card-header .uc-card-title.uc-overflow-hidden div[style].uc-title {
    color: rgba(40, 45, 50, 0.9) !important;
    font-weight: bolder;
    width: 100%;
  }
  #usercentrics-button #uc-center-modal .secondary-basic .uc-card-header {
    padding: 40px 30px;
  }
  #usercentrics-button #uc-center-modal .secondary-basic .uc-card-header .subtitle-text {
    text-align: center;
    width: 100%;
  }
  #usercentrics-button #uc-center-modal .secondary-basic .uc-card-header .uc-card-title {
    text-align: center;
  }
  #uc-central-banner-modal,
  #usercentrics-button #uc-center-modal .secondary-basic .uc-card-header .uc-card-title .uc-secondary-toggle button, #usercentrics-button #uc-center-modal .secondary-advance .uc-card-header .uc-card-title .uc-secondary-toggle button,
  #usercentrics-button #uc-center-modal .user-centric-center-modal .uc-content #uc-btn-open-language-selector-flags,
  #usercentrics-button #uc-center-modal .secondary-basic .uc-card-header .uc-card-title .uc-secondary-toggle .text {
    display: none;
  }
  #usercentrics-button #uc-center-modal .secondary-basic, #usercentrics-button #uc-center-modal .secondary-advance {
    height: 100% !important;
    max-height: 700px !important;
  }
  #usercentrics-button #uc-center-modal .uc-content.secondary-basic .uc-card-body .uc-category-row .uc-category-desc .uc-category-title, #usercentrics-button #uc-center-modal .secondary-advance .uc-card-body .uc-category-row .uc-category-desc .uc-category-title {
    font-size: 18px !important;
    padding-right: 5px !important;
  }
  #usercentrics-button #uc-center-modal .secondary-basic .uc-card-body .uc-category-row input[type="checkbox"]:checked+label, #usercentrics-button #uc-center-modal .secondary-advance .uc-card-body .uc-category-row input[type="checkbox"]:checked+label {
    background-color: darkseagreen !important;
  }
  #usercentrics-button #uc-center-modal .secondary-basic .uc-card-footer .uc-btn-primary.uc-save-settings-button {
    background-color: #ff5f50 !important;
    color: white;
    font-weight: bold !important;
    padding: 12px 24px !important;
    border-radius: 24px !important;
    box-shadow: none !important;
    margin-bottom: 15px !important;  
  }
  .user-centric-center-modal .uc-content.secondary-basic .uc-card-body {
    height: auto !important;
    max-height: fit-content !important;
  }
  .usercentrics-button.layout-1 .user-centric-center-modal .uc-content.secondary-basic {
    height: 100%;
  }
  .uc-card-header {
    background-color: #fff !important;
  }
  .uc-card-body svg {
    width: 15px;

  }
  .uc-card-body svg path {
    fill: #ff5f50;
  }
  .uc-card-body label {
    transform: scale(0.8);
  }
`;

export default GlobalStyle;