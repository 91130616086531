import {actionIds, BaseAction} from "../common";
import {IAdminInvalidatePremiumcodesResponseData} from "../../types/IAdminInvalidatePremiumcodesResponseData";

const adminInvalidatePremiumcodesResponseAction: (data: IAdminInvalidatePremiumcodesResponseData) =>
    BaseAction = (data) => ({
    type: actionIds.ADMIN_INVALIDATE_PREMIUMCODES_RESPONSE,
    payload: {
        premiumcodes: data.premiumcodes?.map(premiumcode => ({
            ...premiumcode,
            order_number: premiumcode.order_number.String,
            orderIds: premiumcode.orderIds.String,
            customerId: premiumcode.customerId.String,
            reason: premiumcode.reason.String,
            updated: premiumcode.updated.String,
        }))
    },
});

export default adminInvalidatePremiumcodesResponseAction;
