import INavigate from "../../types/INavigate";
import {actionIds, BaseAction} from "../common";

const navigate: (data: INavigate) =>
    BaseAction = (data) => ({
    type: actionIds.NAVIGATE,
    payload: data,
});

export default navigate;
