import {actionIds, BaseAction} from "../common";
import {IAdminLoginResponseData} from "../../types/IAdminLoginResponseData";

const adminLoginResponseAction: (data: IAdminLoginResponseData) =>
    BaseAction = (data) => ({
    type: actionIds.ADMIN_LOGIN_RESPONSE,
    payload: {
        ...data,
    },
});

export default adminLoginResponseAction;
