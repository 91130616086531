import INormalizedData from "../types/INormalizedData";
import {API_IBAN_PATH} from "../config/api";
import IApiIbanResponse from "../types/IApiIbanResponse";

const fetchIbanData = (apiUrl: string, iban: string): Promise<INormalizedData<IApiIbanResponse>> => {
    return fetch(apiUrl + API_IBAN_PATH + iban, { headers: { 'cache-control': 'no-store' }})
        .then(res => res.json());
};

export default fetchIbanData;
