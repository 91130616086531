import React from 'react';
import {AnyAction, applyMiddleware, CombinedState, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import RouterWrapper from "./RouterWrapper";
import {Provider} from "react-redux";
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import createSagaMiddleware from 'redux-saga';
import {createBrowserHistory} from 'history';
import {PersistGate} from 'redux-persist/integration/react';
import rootReducer from "./state/reducers";
import setupSocket, {IWebSocket} from "./api/sockets";
import rootSaga from "./state/sagas";
import {IRootState} from "./types/IRootState";
import getConfigAction from "./state/actions/getConfig";
import CookieBanner from "./components/molecules/CookieBanner/CookieBanner";
import GlobalStyle from "./styles/global";
import {gtmPageView} from "./lib/gtm";

const persistConfig = {
    key: 'stadtenergie',
    storage,
    stateReconciler: hardSet,
    blacklist: ['admin']
}

const persistedReducer = persistReducer<CombinedState<IRootState>, AnyAction>(persistConfig, rootReducer)

// Browser History for use with react router & GTM events
const history = createBrowserHistory()
gtmPageView(window.location.pathname)
history.listen((location, action) => {
    window.scrollTo(0, 0)
    gtmPageView(location.pathname)
})

const sagaMiddleware = createSagaMiddleware();
const store = createStore(persistedReducer, undefined, composeWithDevTools(applyMiddleware(sagaMiddleware)))
const persistor = persistStore(store, null, () => {
    // Add initial actions
    store.dispatch(getConfigAction())
})
const socket: IWebSocket = setupSocket(store.dispatch)
sagaMiddleware.run(rootSaga, {socket, history})

const App = () => {
    return (
        <Provider store={store}>
            <GlobalStyle/>
            <CookieBanner/>
            <PersistGate persistor={persistor}>
                <RouterWrapper history={history} />
            </PersistGate>
        </Provider>
    )
}

export default App