export const actionIds = {
    NAVIGATE: 'NAVIGATE',
    ERROR: 'ERROR',
    ERROR_CLEAR: 'ERROR_CLEAR',
    ERROR_ACCESS_DENIED: 'ERROR_ACCESS_DENIED',
    RESET_STORE: 'RESET_STORE',
    ERROR_INTERNAL_SERVER_ERROR: 'ERROR_INTERNAL_SERVER_ERROR',
    LOADING_SET: 'LOADING_SET',
    LOADING_UNSET: 'LOADING_UNSET',
    SET_POSTCODE: 'SET_POSTCODE',
    SET_LOCATION_DETAILS: 'SET_LOCATION_DETAILS',
    GET_LOCATION_DETAILS: 'GET_LOCATION_DETAILS',
    SET_LOCATION_STREETS: 'SET_LOCATION_STREETS',
    GET_LOCATION_STREETS: 'GET_LOCATION_STREETS',
    SET_LOCATION_ERROR: 'SET_LOCATION_ERROR',
    SET_LOCATION_CITY: 'SET_LOCATION_CITY',
    SET_LOCATION_STREET: 'SET_LOCATION_STREET',
    SET_LOCATION_HOUSE_NUMBER: 'SET_LOCATION_HOUSE_NUMBER',
    SET_PRODUCT_DETAILS: 'SET_PRODUCT_DETAILS',
    SET_PRODUCT_ERROR: 'SET_PRODUCT_ERROR',
    RESET_PRODUCT_ERROR: 'RESET_PRODUCT_ERROR',
    GET_PRODUCT_DETAILS: 'GET_PRODUCT_DETAILS',
    VALIDATE_IBAN_REQUEST: 'VALIDATE_IBAN_REQUEST',
    VALIDATE_IBAN_RESPONSE: 'VALIDATE_IBAN_RESPONSE',
    VALIDATE_IBAN_RESET: 'VALIDATE_IBAN_RESET',
    CLEAR_LOCATION_ERROR: 'CLEAR_LOCATION_ERROR',
    SET_CONSUMPTION: 'SET_CONSUMPTION',
    GET_BONUS_PRODUCTS: 'GET_BONUS_PRODUCTS',
    SET_BONUS_PRODUCT: 'SET_BONUS_PRODUCT',
    SET_BONUS_PRODUCTS: 'SET_BONUS_PRODUCTS',
    SET_PRODUCT_TYPE: 'SET_PRODUCT_TYPE',
    BONUS_PRODUCTS: 'BONUS_PRODUCTS',
    CHANGE_CUSTOMER_DATA_FORM: 'CHANGE_CUSTOMER_DATA_FORM',
    CHANGE_CONTRACT_DATA_FORM: 'CHANGE_CONTRACT_DATA_FORM',
    SUBMIT_CHECKOUT_FORM: 'SUBMIT_CHECKOUT_FORM',
    SUBMIT_CHECKOUT_FORM_SUCCESS: 'SUBMIT_CHECKOUT_FORM_SUCCESS',
    GET_CONFIG: 'GET_CONFIG',
    SET_CONFIG: 'SET_CONFIG',
    GET_PROVIDERS: 'GET_PROVIDERS',
    SET_PROVIDERS: 'SET_PROVIDERS',
    SET_PREVIOUS_PROVIDER: 'SET_PREVIOUS_PROVIDER',

    ADMIN_LOGIN: 'ADMIN_LOGIN',
    ADMIN_LOGIN_RESPONSE: 'ADMIN_LOGIN_RESPONSE',
    ADMIN_LOGIN_ERROR_CLEAR: 'ADMIN_LOGIN_ERROR_CLEAR',
    ADMIN_LOGOUT: 'ADMIN_LOGOUT',
    ADMIN_LOGOUT_RESPONSE: 'ADMIN_LOGOUT_RESPONSE',
    ADMIN_FIND_PREMIUMCODES: 'ADMIN_FIND_PREMIUMCODES',
    ADMIN_FIND_PREMIUMCODES_RESPONSE: 'ADMIN_FIND_PREMIUMCODES_RESPONSE',
    ADMIN_CODEPOOL_STATS: 'ADMIN_CODEPOOL_STATS',
    ADMIN_CODEPOOL_STATS_RESPONSE: 'ADMIN_CODEPOOL_STATS_RESPONSE',
    ADMIN_LIST_BONUSPRODUCTS: 'ADMIN_LIST_BONUSPRODUCTS',
    ADMIN_LIST_BONUSPRODUCTS_RESPONSE: 'ADMIN_LIST_BONUSPRODUCTS_RESPONSE',
    ADMIN_GET_BONUSPRODUCT: 'ADMIN_GET_BONUSPRODUCT',
    ADMIN_GET_BONUSPRODUCT_RESPONSE: 'ADMIN_GET_BONUSPRODUCT_RESPONSE',
    ADMIN_UPDATE_BONUSPRODUCT: 'ADMIN_UPDATE_BONUSPRODUCT',
    ADMIN_UPDATE_BONUSPRODUCT_RESPONSE: 'ADMIN_UPDATE_BONUSPRODUCT_RESPONSE',
    ADMIN_INVALIDATE_PREMIUMCODES: 'ADMIN_INVALIDATE_PREMIUMCODES',
    ADMIN_INVALIDATE_PREMIUMCODES_RESPONSE: 'ADMIN_INVALIDATE_PREMIUMCODES_RESPONSE',
};

export interface BaseAction<T = any> {
    type: string;
    payload: T;
}
