import {actionIds, BaseAction} from "../common";
import IConsumptionState from "../../types/IConsumptionState";

const defaultState: IConsumptionState = {
    consumptionGas: 13000,
    consumptionElectricity: 2500,
    type: 'combi'
}

const consumptionReducer = (state: IConsumptionState = defaultState, action: BaseAction): IConsumptionState => {
    switch (action.type) {
        case actionIds.SET_CONSUMPTION:
            if (action.payload.type === 'electricity') {
                return {
                    ...state, ...{
                        consumptionElectricity: action.payload.consumption,
                    }
                }
            } else if (action.payload.type === 'gas') {
                return {
                    ...state, ...{
                        consumptionGas: action.payload.consumption,
                    }
                }
            }

            return state

        case actionIds.RESET_STORE:
            return defaultState

        case actionIds.SET_PRODUCT_TYPE:
            var consumptionData = {
                consumptionGas: 0,
                consumptionElectricity: 0,
            }
            if (action.payload.type === 'electricity') {
                consumptionData.consumptionGas = 0;
                consumptionData.consumptionElectricity = 2500;
            } else if (action.payload.type === 'gas') {
                consumptionData.consumptionGas = 13000;
                consumptionData.consumptionElectricity = 0;
            } else {
                consumptionData.consumptionGas = 13000;
                consumptionData.consumptionElectricity = 2500;
            }

            return {
                ...state,
                ...{type: action.payload.type},
                ...consumptionData
            }

        default:
            return state
    }
}

export default consumptionReducer
