import {actionIds, BaseAction} from "../common";
import {IAdminBonusProductDetails} from "../../types/IAdminBonusProductDetails";

const adminUpdateBonusProductResponseAction: (data: {bonusProduct: IAdminBonusProductDetails}) =>
    BaseAction = (data) => ({
    type: actionIds.ADMIN_UPDATE_BONUSPRODUCT_RESPONSE,
    payload: {
        ...data,
    },
});

export default adminUpdateBonusProductResponseAction;
