import React, { useState, useEffect } from 'react';
// import Button from 'components/Button';
// import Spacer from 'components/Spacer';
import CookieIcon from './icons/CookieIcon';
import {
    Wrapper,
    Container,
    CircleWrapper,
    Circle,
    CircleContainer,
    Title,
    Text,
    LinksWrapper,
    COLOR_ORANGE, Spacer
} from './styles';
import SpinnerAnimation from "../../atoms/SpinnerAnimation/SpinnerAnimation";
import PrimaryButton from "../../atoms/PrimaryButton/PrimaryButton";

const USERCENTRICS_KEY = 'usercentrics';
const IGNORE_COOKIE_BANNER = 'ignore_cookie';

interface CookieBannerProps {

}
/**
 * <CookieBanner/>
 *
 * Renders a cookie banner modal if the consents
 * are not accepted yet.
 *
 * @returns Cookie banner
 */
const CookieBanner: React.FC<CookieBannerProps> = () => {
    const [isVisible, setVisibility] = useState<boolean | null>(null);
    const getWindow: any = () => {
        return (window as any)
    }

    useEffect(() => {
        const usercentricsData = localStorage.getItem(USERCENTRICS_KEY);
        if(usercentricsData) {
            const settings: any = JSON.parse(usercentricsData);
            setVisibility(!settings?.firstUserInteraction?.stateSaved);
        }
    }, []);

    useEffect(() => {
        if (isVisible) {
            document.body.style.overflowY = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.touchAction = 'none';
        } else {
            document.body.style.overflowY = '';
            document.body.style.position = '';
            document.body.style.touchAction = '';
        }
    }, [isVisible]);

    /**
     * Accepts all the concents
     * and close the cookie modal.
     */
    const handleAcceptAllConcents = () => {
        getWindow().usercentrics.acceptAllConsentsAndCloseInitialView();
        setVisibility(false);
    };

    /**
     * Adjust the concents to be selected
     * and close the cookie modal.
     */
    const handleAdjustConcents = () => {
        getWindow().usercentrics.toggleCenteredModalIsVisible();
        process.nextTick(() => {
            const saveButton$ = document.getElementsByClassName('uc-save-settings-button')[0];
            saveButton$.addEventListener('click', () => {
                setVisibility(false);
            });
        });
    };

    /**
     * Go to impress page
     */
    const handleClickImpressum = () => {
        window.open(`https://stadtenergie.de/impressum?${IGNORE_COOKIE_BANNER}=true`);
    };

    /**
     * Go to data protect page
     */
    const handleClickDatem = () => {
        window.open(`https://stadtenergie.de/datenschutz?${IGNORE_COOKIE_BANNER}=true`);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <Wrapper>
            <Container>
                <CircleWrapper>
                    <Circle>
                        <SpinnerAnimation />
                        <CircleContainer>
                            <Title>Hallo!</Title>
                            <Title>Wir verwenden Cookies.</Title>
                            <Spacer/>
                            <CookieIcon />
                            <Spacer/>
                            <PrimaryButton onClick={handleAcceptAllConcents}>
                                Alle akzeptieren
                            </PrimaryButton>
                            <Text color={COLOR_ORANGE} onClick={handleAdjustConcents}>anpassen</Text>
                        </CircleContainer>
                    </Circle>
                </CircleWrapper>
                <LinksWrapper>
                    <Text onClick={handleClickImpressum}>Impressum</Text>
                    <Text>|</Text>
                    <Text onClick={handleClickDatem}>Datenschutz</Text>
                </LinksWrapper>
            </Container>
        </Wrapper >
    );
};

export default CookieBanner;