import routes from "../config/routes";

interface PageEventProps {
    event: string;
    pageName: string;
    [key: string]: any;
}

interface CustomEventProps {
    event: string;
    [key: string]: any;
}

export const gtmPageView = (pageName: string) => {
    if (typeof window === 'undefined') return;

    switch(pageName) {
        case routes.HOME_PAGE:
            pageName = '/home/black-week/'
            break
        case routes.RESULTS_PAGE:
            pageName = '/black-week/praemie/'
            break
        case routes.THANK_YOU_PAGE:
            pageName = '/bestellen/final/'
            break
    }

    const pageEvent: PageEventProps = {
        event: 'page',
        pageName,
        productDivision: 'strom',
    };

    (window as any)?.dataLayer?.push(pageEvent);
};

export const gtmEvent = (event: string) => {
    const customEvent: CustomEventProps = {
        event,
        productDivision: 'strom',
    };

    (window as any)?.dataLayer?.push(customEvent);
};