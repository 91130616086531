import {actionIds, BaseAction} from "../common";
import IProvider from "../../types/IProvider";
import {TProductType} from "../../types/TProductType";

const setProvidersAction: (providers: IProvider[], type: TProductType) =>
    BaseAction = (providers, type) => ({
    type: actionIds.SET_PROVIDERS,
    payload: {
        providers,
        type
    },
});

export default setProvidersAction;
