import {actionIds, BaseAction} from "../common";
import ICity from "../../types/ICity";

const setLocationDetailsAction: (postcode: string, available: boolean, cities: ICity[]) =>
    BaseAction = (postcode, available, cities) => ({
    type: actionIds.SET_LOCATION_DETAILS,
    payload: {
        postcode,
        available,
        cities
    },
});

export default setLocationDetailsAction;
