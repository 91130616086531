import styled from 'styled-components';

export const COLOR_ORANGE = '#ff5f50'
export const COLOR_WHITE = '#fff'
export const COLOR_VIOLET = 'rgb(135, 40, 155)'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(40, 45, 50, 0.9);
`;

export const Container = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CircleWrapper = styled.div``;
export const Circle: any = styled.div`
  position: relative;
  transform-origin: 50% 50%;
  border-radius: 50%;
  width: 420px;
  height: 420px;
  text-align: center;
  background: ${COLOR_WHITE};
  
  transition: width 0.5s cubic-bezier(1, -0.25, 0, 1.25) 0s, height 0.5s cubic-bezier(1, -0.25, 0, 1.25) 0s, border-radius 0.5s cubic-bezier(1, -0.25, 0, 1.25) 0s;

`;
export const CircleContainer = styled.div`
  padding: 80px 60px;
  position: relative;
  background: ${COLOR_WHITE};
  border-radius: inherit;
  width: inherit;
  height: inherit;
  box-sizing: border-box;
  max-width: inherit;
  max-height: inherit;
`;

export const Title = styled.div`
  padding: 0 20px;
  color: ${COLOR_VIOLET};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 24px;
`;

export const Text = styled.div`
  cursor: pointer;
  font-weight: 500;
  margin-top: 30px;
  text-decoration: ${props => props.onClick && 'underline'};
  color: ${props => props.color || COLOR_WHITE};
  font-size: 0.75rem;
`;
export const Spacer = styled.div`
  padding-bottom: 32px;
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  
  ${Text} {
    padding: 0 10px;
  }
`;