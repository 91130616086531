import React from 'react'
import {Route} from "react-router";
import {Redirect} from "react-router-dom";
import {useSelector} from "react-redux";
import {IRootState} from "../../../types/IRootState";
import routes from "../../../config/routes";

const AdminProtectedRoute = ({ children, ...rest }) => {
    const role = useSelector<IRootState>(state => state.admin?.role)
    return (
        <Route {...rest} render={({ location }) =>
            role === 'admin' ? (children) :
                (<Redirect to={routes.ADMIN_LOGIN} />)
        }
        />
    )
}

export default AdminProtectedRoute;