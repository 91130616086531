import {actionIds, BaseAction} from "../common";

const setProductErrorAction: (errorCode, errorMessage) =>
    BaseAction = (errorCode, errorMessage) => ({
    type: actionIds.SET_PRODUCT_ERROR,
    payload: {
        errorCode,
        errorMessage
    },
});

export default setProductErrorAction;
