import {actionIds, BaseAction} from "../common";
import IFormSubmitSuccess from "../../types/IFormSubmitSuccess";

const submitCheckoutFormSuccessAction: (data: IFormSubmitSuccess) =>
    BaseAction = (data) => ({
    type: actionIds.SUBMIT_CHECKOUT_FORM_SUCCESS,
    payload: {
        success: data.success,
        orderNumber: data.orderNumber,
        premiumCode: data.premiumCode,
    },
});

export default submitCheckoutFormSuccessAction;
