import {actionIds, BaseAction} from "../common";
import {IConfigState} from "../../types/IConfigState";

const defaultState: IConfigState = {
    apiUrl: '',
    specialOffersAvailable: []
}

const configReducer = (state: IConfigState = defaultState, action: BaseAction): IConfigState => {
    switch (action.type) {
        case actionIds.SET_CONFIG:
            return {
                ...state,
                apiUrl: action.payload.apiUrl,
                specialOffersAvailable: action.payload.specialOffersAvailable
            }

        default:
            return state
    }
}

export default configReducer
