import INormalizedData from "../types/INormalizedData";
import IApiLocationResponse from "../types/IApiLocationResponse";
import {API_LOCATION_PATH, CAMPAIGN_IDENTIFIER} from "../config/api";

const fetchStreetsData = (apiUrl: string, postcode: string): Promise<INormalizedData<IApiLocationResponse>> => {
    return fetch(apiUrl + API_LOCATION_PATH + postcode + `/streets?campaignIdentifier=${CAMPAIGN_IDENTIFIER}`, { headers: { 'cache-control': 'no-store' }})
        .then(res => res.json());
};

export default fetchStreetsData;
