import {actionIds, BaseAction} from "../common";
import IAdminState from "../../types/IAdminState";

const defaultState: IAdminState = {
    loginError: false,
    role: '',
    apiToken: '',
    codepoolStats: [],
    premiumcodes: [],
    premiumcodesHasMore: false,
    premiumcodesPage: 0,
    premiumcodesTotal: 0,
    invalidatedPremiumcodes: [],
    bonusProducts: [],
    selectedBonusProduct: null,
}

const adminReducer = (state: IAdminState = defaultState, action: BaseAction): IAdminState => {
    switch (action.type) {
        case actionIds.ADMIN_LOGIN_RESPONSE:
            return {
                ...state,
                role: action.payload.role,
                apiToken: action.payload.apiToken,
                loginError: !action.payload.success
            }

        case actionIds.ADMIN_LOGIN_ERROR_CLEAR:
            return {
                ...state,
                loginError: false
            }

        case actionIds.ADMIN_LOGOUT:
            return {
                ...defaultState
            }

        case actionIds.ERROR_ACCESS_DENIED:
            return {
                ...defaultState
            }

        case actionIds.ADMIN_FIND_PREMIUMCODES_RESPONSE:
            var premiumcodes = state.premiumcodes
            if (action.payload.page > 0) {
                premiumcodes = [...premiumcodes, ...action.payload.premiumcodes];
            } else {
                premiumcodes = action.payload.premiumcodes;
            }

            return {
                ...state,
                premiumcodes: premiumcodes,
                premiumcodesHasMore: (action.payload.page + 1 < action.payload.total_pages),
                premiumcodesPage: action.payload.page,
                premiumcodesTotal: action.payload.total_results,
            }

        case actionIds.ADMIN_INVALIDATE_PREMIUMCODES:
            return {
                ...state,
                invalidatedPremiumcodes: [],
            }

        case actionIds.ADMIN_INVALIDATE_PREMIUMCODES_RESPONSE:
            return {
                ...state,
                invalidatedPremiumcodes: action.payload.premiumcodes,
            }

        case actionIds.ADMIN_CODEPOOL_STATS_RESPONSE:
            return {
                ...state,
                codepoolStats: action.payload.codepoolStats,
            }

        case actionIds.ADMIN_LIST_BONUSPRODUCTS_RESPONSE:
            return {
                ...state,
                bonusProducts: action.payload.bonusProducts,
            }

        case actionIds.ADMIN_GET_BONUSPRODUCT_RESPONSE:
            action.payload.bonusProduct.last_updated = 0;
            return {
                ...state,
                selectedBonusProduct: action.payload.bonusProduct,
            }

        case actionIds.ADMIN_UPDATE_BONUSPRODUCT_RESPONSE:
            action.payload.bonusProduct.last_updated = Date.now();
            return {
                ...state,
                selectedBonusProduct: action.payload.bonusProduct,
            }

        default:
            return state
    }
}

export default adminReducer;
