import {actionIds, BaseAction} from "../common";
import {IAdminBonusProductDetails} from "../../types/IAdminBonusProductDetails";

const adminGetBonusProductResponseAction: (data: {bonusProduct: IAdminBonusProductDetails}) =>
    BaseAction = (data) => ({
    type: actionIds.ADMIN_GET_BONUSPRODUCT_RESPONSE,
    payload: {
        ...data,
    },
});

export default adminGetBonusProductResponseAction;
